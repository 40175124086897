import React from "react";
import "./about.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import aboutimg from "../../Media/Gambarabout (2).webp";
import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";

const About = () => {
  const handleConsultationClick = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285880854197&text=Halo%20Putri,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Podomoro%20Park%20Bandung%20(About)https://marketing-podomoroparkbdg.com//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };

  return (
    <div className="about">
      <div id="about" className="container-about">
        <h1>The Beauty & A World Class Home Resort</h1>
        <div className="container-desk">
          <div className="desk">
            Podomoro Park Bandung merupakan hunian yang dikembangkan oleh PT.
            Agung Podomoro Land Tbk di daerah Buah Batu, Bandung. Dengan luas
            kawansan lebih dari 100 hektar, Podomoro Park Bandung mengusung
            konsep "Harmony With Nature". Guna meningkatkan kenyaman dan untuk
            memenuhi kebutuhan para penghuni Podomoro Park Bandung menyediakan
            berbagai fasilitas, seperti :
          </div>
        </div>
        <div className="container-benefit">
          <div className="benefit-list">
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="#FEC00E" />
              <span style={{color: "rgb(84, 84, 84)"}}>
                &nbsp;&nbsp;Clubhouse
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="#FEC00E" />
              <span style={{color: "rgb(84, 84, 84)"}}>
                &nbsp;&nbsp;Communal Park
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="#FEC00E" />
              <span style={{color: "rgb(84, 84, 84)"}}>
                &nbsp;&nbsp;Swimming Pool
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="#FEC00E" />
              <span style={{color: "rgb(84, 84, 84)"}}>&nbsp;&nbsp;Gym</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="#FEC00E" />
              <span style={{color: "rgb(84, 84, 84)"}}>
                &nbsp;&nbsp;Multifunction Room
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="#FEC00E" />
              <span style={{color: "rgb(84, 84, 84)"}}>
                &nbsp;&nbsp;Bowling Alley
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="#FEC00E" />
              <span style={{color: "rgb(84, 84, 84)"}}>
                &nbsp;&nbsp;Private Theatre
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="#FEC00E" />
              <span style={{color: "rgb(84, 84, 84)"}}>
                &nbsp;&nbsp;Games Room
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="#FEC00E" />
              <span style={{color: "rgb(84, 84, 84)"}}>
                &nbsp;&nbsp;Futsal Court
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="#FEC00E" />
              <span style={{color: "rgb(84, 84, 84)"}}>
                &nbsp;&nbsp;Basketball Court
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="#FEC00E" />
              <span style={{color: "rgb(84, 84, 84)"}}>
                &nbsp;&nbsp;Camping Ground & BBQ Area
              </span>
            </div>
          </div>
        </div>
        <div className="button">
          <div className="container-wa">
            <button
              className="buttonpenawaran"
              onClick={handleConsultationClick}>
              Learn More
            </button>
          </div>
        </div>
      </div>
      <div className="gambarabout">
        <img src={aboutimg} alt={aboutimg} />
      </div>
    </div>
  );
};

export default About;
