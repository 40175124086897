import React from "react";
import "./home.scss";
import Navbar from "../Section/Header/navbar";
import Banner from "../Section/Banner/banner";
import About from "../Section/About/about";
import Fasilitas from "../Section/Fasilitas/fasilitas.jsx";
import Promo from "../Section/Promo/promo.jsx";
import Avanyaavega from "../Section/Avanyaavega/avanya.jsx";
import Produk from "../Section/Produk/produk";
import Student from "../Section/Student/Student.jsx";
import Surrounding from "../Section/surrounding/Surrounding.jsx";
import Lokasi from "../Section/Lokasi/lokasi.jsx";
import Footer from "../Section/Footer/footer.jsx";
import Scrolltohashelement from "../Pages/ScrollToHashElement";
import tombol from "../Media/tombol.webp";
const home = () => {
  const fungsiganteng = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285880854197&text=Halo%20Putri,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Podomoro%20Park%20Bandung%20https://marketing-podomoroparkbdg.com//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  return (
    <div className="home">
      <Scrolltohashelement />
      <Navbar />
      <Banner />
      <About />
      <Fasilitas />
      <Promo />
      <Student />
      <Avanyaavega />
      <Produk />
      <Surrounding />
      <Lokasi />
      <Footer />
      <img
        onClick={fungsiganteng}
        src={tombol}
        className="tombolwa"
        alt="Hubungi Sekarang!"
      />
    </div>
  );
};

export default home;
