import React from "react";
import "./Student.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Studentfeatureimages from "../../Media/Produk/Student House/studentfeature";
import Studentcardimages from "../../Media/Produk/Student House/Studenthouse";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {
  faArrowsUpDownLeftRight,
  faBed,
  faHouse,
  faMedal,
  faShower,
} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

const settings1 = {
  dots: true,
  arrows: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
};
const whatsappMtownResidence = () => {
  const whatsappLink =
    "https://api.whatsapp.com/send/?phone=6285880854197&text=Halo%20Putri,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Podomoro%20Park%20Bandung%20(Student%20House)%20https://marketing-podomoroparkbdg.com//&type=phone_number&app_absent=0";
  window.location.href = whatsappLink;
};

const apartemen = () => {
  return (
    <div id="studenthouse" className="apartment">
      <div className="container-header1"> New Launching Student House</div>
      <div className="heroregia">
        <div className="slider-regia">
          <Slider {...settings1}>
            {Studentfeatureimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
        </div>
        <div className="cardstudent">
          <Slider {...settings1}>
            {Studentcardimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Student House</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="#C88C39"
                icon={faMedal}
              />
              <div className="namalokasi">Minimalist Hoouse</div>
            </div>
          </div>
          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#334442" icon={faHouse} />
            <span> 70m²</span>
            <FontAwesomeIcon color="#334442" icon={faArrowsUpDownLeftRight} />
            <span> 31m²</span>
            <FontAwesomeIcon color="#334442" icon={faBed} />
            <span> 2</span>
            <FontAwesomeIcon color="#334442" icon={faShower} />
            <span> 2</span>
          </div>
          <hr />
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">4 Juta/</div>
              <div className="ket">Bulan</div>
            </div>
          </div>
          <div className="cluster-button">
            <button
              onClick={whatsappMtownResidence}
              className="cluster-whatsapp">
              <FontAwesomeIcon size="lg" icon={faWhatsapp} />
              &nbsp;Whatsapp
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default apartemen;
