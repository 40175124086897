import React from "react";
import "./produk.scss";
import data from "../../Component/Card/data.json";
import Card from "../../Component/Card/card";
import Brahmapuriimages from "../../Media/Produk/Brahmapuri/Brahmapuri";
import Sadyagriyaimages from "../../Media/Produk/Sadyagriya";
import Wangsagriyaimages from "../../Media/Produk/Wangsagriya";
import Fashagriyaimages from "../../Media/Produk/Fashagriya";
import Dharmapuriimages from "../../Media/Produk/Dharmapuri";
import Millenialsimages from "../../Media/Produk/Millenials";

const imageMap = {
  Brahmapuriimages,
  Sadyagriyaimages,
  Wangsagriyaimages,
  Fashagriyaimages,
  Dharmapuriimages,
  Millenialsimages,

  // Add other image arrays here if you have more sets
};

const produk = () => {
  return (
    <div id="rumah" className="produk">
      <h1>Our Residentials</h1>

      <div className="cardhome-containerf">
        {data.cards.map((card, index) => {
          // Determine the logo dynamically
          // Determine the logo dynamically
          return (
            <Card
              key={index}
              carousel={imageMap[card.imagesKey]}
              title={card.title}
              subtitle={card.subtitle}
              price={card.price}
              subprice={card.subprice}
              details={card.details}
              whatsappLink={card.whatsappLink} // Pass the WhatsApp link
            />
          );
        })}
      </div>
    </div>
  );
};

export default produk;
