import React from "react";
import "./avanyavega.scss";
import Avanyaavega1 from "./avanyaavega1/avanyavega1.jsx";

const apartemen = () => {
  return (
    <div id="avanyaavega" className="apartment">
      <div className="container-header1"> New Launching Avanya & Avega</div>
      <div>
        <div className="Judul"></div>
        <Avanyaavega1 />
      </div>
    </div>
  );
};

export default apartemen;
