import Fasilitas1 from '../../../src/Media/Fasilitas/Fasilitas (1).webp'
import Fasilitas2 from '../../../src/Media/Fasilitas/Fasilitas (2).webp'
import Fasilitas3 from '../../../src/Media/Fasilitas/Fasilitas (3).webp'
import Fasilitas4 from '../../../src/Media/Fasilitas/Fasilitas (4).webp'
import Fasilitas5 from '../../../src/Media/Fasilitas/Fasilitas (5).webp'
import Fasilitas6 from '../../../src/Media/Fasilitas/Fasilitas (6).webp'
import Fasilitas7 from '../../../src/Media/Fasilitas/Fasilitas (7).webp'
import Fasilitas8 from '../../../src/Media/Fasilitas/Fasilitas (8).webp'
import Fasilitas9 from '../../../src/Media/Fasilitas/Fasilitas (9).webp'
import Fasilitas10 from '../../../src/Media/Fasilitas/Fasilitas (10).webp'
import Fasilitas11 from '../../../src/Media/Fasilitas/Fasilitas (11).webp'
import Fasilitas12 from '../../../src/Media/Fasilitas/Fasilitas (12).webp'
import Fasilitas13 from '../../../src/Media/Fasilitas/Fasilitas (13).webp'
import Fasilitas14 from '../../../src/Media/Fasilitas/Fasilitas (14).webp'
import Fasilitas15 from '../../../src/Media/Fasilitas/Fasilitas (15).webp'
import Fasilitas16 from '../../../src/Media/Fasilitas/Fasilitas (16).webp'


const Fasilitasimages =[
    Fasilitas1,Fasilitas2,Fasilitas3,Fasilitas4,Fasilitas5,Fasilitas6,Fasilitas7,Fasilitas8,Fasilitas9,Fasilitas10,Fasilitas11,Fasilitas12
    ,Fasilitas13,Fasilitas14,Fasilitas15,Fasilitas16
]

export default Fasilitasimages;