import React from "react";
import "./promo.scss";
import penawaran from "../../Media/Hardsell_pdmbandung.webp";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";

const Penawaran = () => {
  const Cherrywa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285880854197&text=Halo%20Putri,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Podomoro%20Park%20Bandung%20(Promo)%20https://marketing-podomoroparkbdg.com//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  return (
    <div id="promo" className="promosumbog">
      <div className="promo-sumbog">
        <div className="gambarpromo">
          <img src={penawaran} alt={penawaran} />
        </div>
        <div className="ket-promo">
          <div className="judul">
            <h1>Promo Podomoro Park Bandung</h1>
          </div>
          <div className="promolist">
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckCircle} />
              <span style={{color: "White"}}>&nbsp;&nbsp;Siap Huni</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckCircle} />
              <span style={{color: "White"}}>
                &nbsp;&nbsp;Discount up to 10%
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckCircle} />
              <span style={{color: "White"}}>&nbsp;&nbsp;Tanpa DP</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckCircle} />
              <span style={{color: "White"}}>
                &nbsp;&nbsp;100% KPR Approved
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckCircle} />
              <span style={{color: "White"}}>
                &nbsp;&nbsp;Diskon Ratusan Juta Rupiah!
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckCircle} />
              <span style={{color: "White"}}>
                &nbsp;&nbsp;Logam Mulia 200 Gram
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckCircle} />
              <span style={{color: "White"}}>&nbsp;&nbsp;Hyundai Ioniq 5 </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckCircle} />
              <span style={{color: "White"}}>
                &nbsp;&nbsp;Free Lifetime Membership Clubhouse
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckCircle} />
              <span style={{color: "White"}}>
                &nbsp;&nbsp;100% Friendly Eco Living - Bebas Polusi
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckCircle} />
              <span style={{color: "White"}}>
                &nbsp;&nbsp;100% Friendly Eco Living - Bebas Sampah
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckCircle} />
              <span style={{color: "White"}}>
                &nbsp;&nbsp;Cicilan start 4 juta/bulan
              </span>
            </div>
          </div>
          <button className="buttonpenawaran" onClick={Cherrywa}>
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
};

export default Penawaran;
