import React from "react";
import "./lokasi.scss";
import mapsimage from "../../Media/maps_desktop.png";
import mobilemaps from "../../Media/maps_mobile.png";

const lokasi = () => {
  return (
    <div id="lokasi" className="lokasi">
      <div className="judul">Location </div>
      <div className="maps-lokasi">
        <img className="img-lokasi-dekstop" src={mapsimage} alt="maps" />
        <img className="img-lokasi-mobile" src={mobilemaps} alt="maps" />
      </div>
    </div>
  );
};

export default lokasi;
