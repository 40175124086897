import React from "react";
import "./avanyavega1.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Avanyaimages from "../../../Media/Produk/Avanya/Avanya";
import Avegaimages from "../../../Media/Produk/Avega/Avega";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faMedal,
} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faBed} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

const Produk1 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const whatsappMtownResidence = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285880854197&text=Halo%20Putri,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Podomoro%20Park%20Bandung%20(Avanya)https://marketing-podomoroparkbdg.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const whatsappMtownSignature = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285880854197&text=Halo%20Putri,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Podomoro%20Park%20Bandung%20(Avega)https://marketing-podomoroparkbdg.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const whatsappRainbow = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285215127964&text=Halo%20Marketing%20Summarecon,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Apartemen%20Rainbow%20Springs%20https://marketinggadingserpong.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="container">
      <div className="containercard-apart">
        <div className="card">
          <Slider {...settings}>
            {Avanyaimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Avanya</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="#C88C39"
                icon={faMedal}
              />
              <div className="namalokasi">Minimalist Hoouse</div>
            </div>
          </div>
          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#063f20" icon={faHouse} />
            <span> 70m²</span>
            <FontAwesomeIcon color="#063f20" icon={faArrowsUpDownLeftRight} />
            <span> 31m²</span>
            <FontAwesomeIcon color="#063f20" icon={faBed} />
            <span> 2</span>
            <FontAwesomeIcon color="#063f20" icon={faShower} />
            <span> 2</span>
          </div>
          <hr />
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">4 Juta/</div>
              <div className="ket">Bulan</div>
            </div>
          </div>
          <div className="clusterbutton">
            <button
              onClick={whatsappMtownResidence}
              className="cluster-whatsapp">
              <FontAwesomeIcon size="lg" icon={faWhatsapp} />
              &nbsp;Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Avegaimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Avega</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="#C88C39"
                icon={faMedal}
              />
              <div className="namalokasi">Minimalist House</div>
            </div>
          </div>
          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#063f20" icon={faHouse} />
            <span> 60m²</span>
            <FontAwesomeIcon color="#063f20" icon={faArrowsUpDownLeftRight} />
            <span> 31m²</span>
            <FontAwesomeIcon color="#063f20" icon={faBed} />
            <span> 2</span>
            <FontAwesomeIcon color="#063f20" icon={faShower} />
            <span> 1</span>
          </div>
          <hr />
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">4 Juta/</div>
              <div className="ket">Bulan</div>
            </div>
          </div>
          <div className="clusterbutton">
            <button
              onClick={whatsappMtownSignature}
              className="cluster-whatsapp">
              <FontAwesomeIcon size="lg" icon={faWhatsapp} />
              &nbsp;Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Produk1;
